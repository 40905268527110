import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19')
];

export const server_loads = [2];

export const dictionary = {
		"/(app)/(authenticated)": [4,[2]],
		"/(app)/(authenticated)/events": [~5,[2]],
		"/(app)/(authenticated)/events/new": [~7,[2]],
		"/(app)/(authenticated)/events/[event]": [~6,[2]],
		"/(app)/(authenticated)/locations": [~8,[2]],
		"/(app)/(authenticated)/locations/[location]": [~9,[2]],
		"/(app)/(authenticated)/orders": [~10,[2]],
		"/(app)/(authenticated)/orders/new": [~12,[2]],
		"/(app)/(authenticated)/orders/[order]": [~11,[2]],
		"/(app)/(authenticated)/productions": [~13,[2]],
		"/(app)/(authenticated)/productions/new": [~15,[2]],
		"/(app)/(authenticated)/productions/[production]": [~14,[2],[,3]],
		"/(app)/signin": [19],
		"/(app)/(authenticated)/vouchers": [~16,[2]],
		"/(app)/(authenticated)/vouchers/new": [~18,[2]],
		"/(app)/(authenticated)/vouchers/[voucher]": [~17,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';